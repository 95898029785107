import React from 'react'
// custom
import Layout from '../components/user-order/Layout'
import AdminListMenu from '../components/user-order/AdminListMenu'

export default function IndexPage() {
  return (
    <Layout>
      <AdminListMenu />
    </Layout>
  )
}
