import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { filter, map } from 'lodash'
import { Link } from 'gatsby'
// custom
import { getMenuAll, removeMenuByKey } from './api/services'

export default function AdminListMenu() {
  const [menu, setMenu] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getMenuAll()
      .then(res => {
        const data = map(res, item => {
          return { ...item.data, menuKey: item.ref.value.id }
        })
        setMenu(data)

        toast.success('fetched all menu')
      })
      .finally(() => setLoading(false))

    return () => setLoading(false)
  }, [])

  if (loading) {
    return <>loading...</>
  }
  return (
    <ul style={{ listStyleType: `auto` }}>
      {menu.map((item, index) => (
        <li key={index} style={{ padding: `0.5rem` }}>
          <span
            role="presentation"
            className="_pointer"
            style={{ paddingRight: `0.5rem` }}
            onClick={() => {
              removeMenuByKey(item.menuKey).then(response => {
                const _menuKey = response.ref.value.id
                const _menu = filter(menu, item => item.menuKey !== _menuKey)
                setMenu(_menu)
              })
            }}
          >
            ❌
          </span>
          {` `}
          <Link to={`/menu?tel=${item.telephone}`}>
            {item.telephone}
            {item?.name ? ` - ${item.name}` : ``}
          </Link>
        </li>
      ))}
    </ul>
  )
}
